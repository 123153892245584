import * as SearchPageComponents from './search-page.styles';
import * as FormComponents from '../shared/form.styles';
import * as CommonComponents from '../shared/common.styles';
import { BoxIcon } from '../shared/icons';
import { SearchValidationSchema } from './search.schema';
import { useMutation } from 'react-query';
import { RetiramoApi } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { translateSearchError } from './search-page.utils';
import FormField from '../shared/form-field.component';
import { Formik } from 'formik';
import { useSearchParam } from 'react-use';

const initialValues = { search: '' };

const SearchPage = () => {
  const sid = useSearchParam('sid');
  const {
    isLoading: isSearching,
    isError: hasSearchFailed,
    error: searchError,
    mutateAsync: searchOrder
  } = useMutation((tracking: string) => RetiramoApi.searchOrder(sid, tracking));
  const formErrorMessage = translateSearchError(searchError);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    searchOrder(values.search).then((data) => {
      navigate(`/order?sid=${sid}&oid=${data.order_id}`);
    });
  };
  return (
    <SearchPageComponents.Container>
      <FormComponents.FormLogo />
      <FormComponents.FormTitle>Pesquisar Pedido</FormComponents.FormTitle>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={SearchValidationSchema}>
        <FormComponents.FormContainer>
          <FormField name="search" label="Código de Rastreio" required />
          {hasSearchFailed && (
            <FormComponents.FormErrorMessage>{formErrorMessage}</FormComponents.FormErrorMessage>
          )}
          <FormComponents.FormButton disabled={isSearching} type="submit">
            <FormComponents.FormButtonIcon>
              <BoxIcon />
            </FormComponents.FormButtonIcon>
            {isSearching ? 'Pesquisando...' : 'Pesquisar'}
          </FormComponents.FormButton>
        </FormComponents.FormContainer>
      </Formik>
      <CommonComponents.PoweredByImage />
    </SearchPageComponents.Container>
  );
};

export default SearchPage;
