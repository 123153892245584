import { AxiosError } from 'axios';

enum WithdrawOrderErrors {
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  DELIVERY_NOT_FOUND = 'DELIVERY_NOT_FOUND',
  INVALID_TOKEN = 'INVALID_TOKEN',
  ERROR_UPDATING_DELIVERY_TO_SUCCESSFUL = 'ERROR_UPDATING_DELIVERY_TO_SUCCESSFUL',
  ORDER_NOT_DELIVERIABLE = 'ORDER_NOT_DELIVERIABLE',
  ORDER_ALREADY_WITHDRAWN = 'ORDER_ALREADY_WITHDRAWN'
}

export const translateWithdrawOrderError = (error: any) => {
  if (!(error instanceof AxiosError) || !error.response.data.code)
    return 'Erro desconhecido, Tente novamente mais tarde.';
  switch (error.response.data.code) {
    case WithdrawOrderErrors.DELIVERY_NOT_FOUND:
    case WithdrawOrderErrors.ORDER_NOT_FOUND:
      return 'Pedido não encontrado, Tente novamente mais tarde.';
    case WithdrawOrderErrors.ORDER_NOT_DELIVERIABLE:
      return 'Pedido não pode ser retirado, Tente novamente mais tarde.';
    case WithdrawOrderErrors.INVALID_TOKEN:
      return 'Token inválido, confira se o campo "Código de Confirmação" corresponde ao ultimo código recebido no seu email ou telefone.';
    case WithdrawOrderErrors.ERROR_UPDATING_DELIVERY_TO_SUCCESSFUL:
      return 'Erro ao atualizar entrega para sucesso, Tente novamente mais tarde.';
    case WithdrawOrderErrors.ORDER_ALREADY_WITHDRAWN:
      return 'Pedido já foi retirado.';
    default:
      return 'Erro desconhecido, Tente novamente mais tarde.';
  }
};

export const flatAddress = (address: any) => {
  try {
    const { street, city, neighborhood, state, street_number, zip_code } = address;
    return `${street}, ${neighborhood} ${street_number} - ${city}/${state} - ${zip_code}`;
  } catch {
    return '';
  }
};

export const onlyNumbers = (str: string) => {
  try {
    return str.replace(/\D/g, '');
  } catch {
    return str || '';
  }
};
