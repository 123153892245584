import styled from 'styled-components';
import { FormButton } from '../shared/form.styles';

export const PageContainer = styled.div`
  background-color: ${(p) => p.theme.colors.main};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem 0;
  letter-spacing: 0.08rem;
`;

export const WaitTime = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-variant-numeric: tabular-nums;
  color: ${(p) => p.theme.colors.mainContrast};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem auto;
  align-items: center;
  width: 100%;
  padding: 1rem 4rem;
  max-width: 400px;
  background-color: white;
  border-radius: 0.75rem;
  font-family: Inter;

  @media screen and (max-width: 400px) {
    margin: 1.5rem 1rem;
    width: fit-content;
    padding: 1rem 2rem;
  }
`;

export const ConfirmationTitle = styled.h1`
  font-size: 1.8rem;
  color: #080808;
`;

export const ConfirmationSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

export const ConfirmationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.5rem 0;
  font-size: 1.5rem;
  color: #3e3e3e;
`;

export const ConfirmationLabel = styled.p`
  margin: 0;
  margin-right: 1rem;
`;

export const ConfirmationValue = styled.p`
  margin: 0;
`;

export const ConfirmationRedirectButton = styled(FormButton)`
  margin: 1rem auto;
  padding: 1.5rem 1.5rem;
`;
