import { AxiosError } from 'axios';

enum SearchOrderErrors {
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  ORDER_NOT_READY_FOR_PICKUP = 'ORDER_NOT_READY_FOR_PICKUP',
  ORDER_ALREADY_WITHDRAWN = 'ORDER_ALREADY_WITHDRAWN'
}

export const translateSearchError = (error: any) => {
  if (!(error instanceof AxiosError) || !error.response.data.code)
    return 'Erro desconhecido, Tente novamente mais tarde.';
  switch (error.response.data.code) {
    case SearchOrderErrors.ORDER_NOT_FOUND:
      return 'Pedido não encontrado.';
    case SearchOrderErrors.ORDER_NOT_READY_FOR_PICKUP:
      return 'Pedido ainda não está pronto para ser retirado.';
    case SearchOrderErrors.ORDER_ALREADY_WITHDRAWN:
      return 'Pedido já foi retirado.';
    default:
      return 'Erro desconhecido, Tente novamente mais tarde.';
  }
};
