/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ProjectTheme } from '@abbiamo/seller-theme-utils/dist';
import api from '.';
import { RetiramoTheme } from '../hooks/useSellerTheme';

export interface WithdrawOrderParams {
  name: string;
  code: string;
  description?: string;
  document_number: string;
}

interface Address {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
  reference: string;
}

interface WithdrawOrderResponse {
  order_number: string;
  tracking: string;
  invoice_number: string | null;
  customer: {
    name: string;
    document_number: string | null;
  };
  receiver: {
    name: string;
    document_number: string;
    description: string;
  };
  date: string;
  filled_at: string;
  address: Address;
}

export interface RetiramoOrder {
  id: string;
  seller_group_id: string;
  number: string;
  tracking: string;
  address: Address;
}

export class RetiramoApi {
  static async getSellerTheme(sid: string) {
    return api.get<RetiramoTheme>(`/seller/${sid}/white-label`).then((v) => v.data);
  }

  static async resendToken(oid: string): Promise<void> {
    return api.post(`/order/${oid}/takeout/resend-token`);
  }

  static async withdrawOrder(oid: string, body: WithdrawOrderParams) {
    return api
      .post<WithdrawOrderResponse>(`/order/${oid}/takeout/success`, body)
      .then((v) => v.data);
  }

  static async searchOrder(sid: string, searchTracking: string) {
    return api
      .get<{ order_id: string }>(`/search/seller/${sid}/order/tracking/${searchTracking}`)
      .then((v) => v.data);
  }

  static async getOrder(oid: string) {
    return api.get<RetiramoOrder>(`/order/${oid}`).then((v) => v.data);
  }
}
