import { Field } from 'formik';
import { HTMLInputTypeAttribute } from 'react';
import * as FormComponents from '../shared/form.styles';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

const FormField: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  asComponent?: string;
  mask?: ReactInputMaskProps;
  placeholder?: string;
}> = ({
  name,
  required = false,
  label,
  type = 'text',
  asComponent = 'input',
  mask,
  placeholder
}) => {
  return (
    <FormComponents.FormFieldContainer>
      <FormComponents.FormFieldLabel htmlFor={name}>
        {label} {required ? <FormComponents.Red>*</FormComponents.Red> : <></>}
      </FormComponents.FormFieldLabel>
      <Field name={name}>
        {({ field }) => {
          if (mask)
            return (
              <ReactInputMask placeholder={placeholder} {...mask} {...field} type={type} id={name}>
                {(inputProps) => <FormComponents.FormField {...inputProps} />}
              </ReactInputMask>
            );
          return (
            <FormComponents.FormField
              placeholder={placeholder}
              as={asComponent}
              type={type}
              id={name}
              {...field}
            />
          );
        }}
      </Field>
      <FormComponents.FormFieldErrorMessage name={name} component="div" />
    </FormComponents.FormFieldContainer>
  );
};

export default FormField;
