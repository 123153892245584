import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { queryClient } from './deps';
import NotFoundPage from './features/404/not-found-page';
import FormPage from './features/form/form-page';
import SearchPage from './features/search/search-page';
import Headers from './features/shared/headers/headers.component';
import { defaultTheme } from './hooks/useSellerTheme';
import { RetiramoApi } from './services/api';
import ThemeDeps from './theme-deps';

const Wrap: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ThemeDeps>
    <Headers />
    {children}
  </ThemeDeps>
);

const router = createBrowserRouter([
  {
    loader: async ({ request }) => {
      const sid = new URL(request.url).searchParams.get('sid');
      if (!sid) {
        return redirect('/404');
      }
      await queryClient.fetchQuery(['seller-theme', sid], () =>
        RetiramoApi.getSellerTheme(sid).catch(() => defaultTheme)
      );
      return null;
    },
    children: [
      {
        element: (
          <Wrap>
            <SearchPage />
          </Wrap>
        ),
        index: true
      },
      {
        path: '/order',
        loader: async ({ request }) => {
          const params = new URL(request.url).searchParams;
          const oid = params.get('oid');
          const sid = params.get('sid');
          if (!oid) {
            return redirect(`/404?sid=${sid}`);
          }
          try {
            await queryClient.fetchQuery(['ORDER', oid], () => RetiramoApi.getOrder(oid));

            return null;
          } catch {
            return redirect(`/404?sid=${sid}`);
          }
        },
        element: (
          <Wrap>
            <FormPage />
          </Wrap>
        )
      }
    ]
  },
  {
    path: '*',
    loader: async ({ request }) => {
      const sid = new URL(request.url).searchParams.get('sid');
      await queryClient.fetchQuery(['seller-theme', sid], () =>
        sid ? RetiramoApi.getSellerTheme(sid).catch(() => defaultTheme) : defaultTheme
      );
    },
    element: (
      <Wrap>
        <NotFoundPage />
      </Wrap>
    )
  }
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
