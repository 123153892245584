import { createRoot } from 'react-dom/client';

import Deps from './deps';
import Routes from './routes';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Deps>
    <Routes />
  </Deps>
);
