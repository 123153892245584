export const BoxIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 7.83329L5.25 3.50829M1.725 5.79996L9 10.0083L16.275 5.79996M9 18.4V9.99996M16.5 13.3333V6.66663C16.4997 6.37435 16.4225 6.0873 16.2763 5.83426C16.13 5.58122 15.9198 5.37109 15.6667 5.22496L9.83333 1.89163C9.57997 1.74535 9.29256 1.66833 9 1.66833C8.70744 1.66833 8.42003 1.74535 8.16667 1.89163L2.33333 5.22496C2.08022 5.37109 1.86998 5.58122 1.72372 5.83426C1.57745 6.0873 1.5003 6.37435 1.5 6.66663V13.3333C1.5003 13.6256 1.57745 13.9126 1.72372 14.1657C1.86998 14.4187 2.08022 14.6288 2.33333 14.775L8.16667 18.1083C8.42003 18.2546 8.70744 18.3316 9 18.3316C9.29256 18.3316 9.57997 18.2546 9.83333 18.1083L15.6667 14.775C15.9198 14.6288 16.13 14.4187 16.2763 14.1657C16.4225 13.9126 16.4997 13.6256 16.5 13.3333Z"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8337 3.00004C17.8337 2.08337 17.0837 1.33337 16.167 1.33337H2.83366C1.91699 1.33337 1.16699 2.08337 1.16699 3.00004M17.8337 3.00004V13C17.8337 13.9167 17.0837 14.6667 16.167 14.6667H2.83366C1.91699 14.6667 1.16699 13.9167 1.16699 13V3.00004M17.8337 3.00004L9.50033 8.83337L1.16699 3.00004"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
