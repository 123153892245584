import { DefaultTheme } from 'styled-components';
import { SellerTheme } from '@abbiamo/seller-theme-utils/dist';

export const getTheme = (theme: SellerTheme): DefaultTheme => ({
  colors: {
    main: theme.primary.value,
    mainContrast: theme.primary.safeColorInsideContainer,
    mainContrastAgainstBg: theme.primary.safeColorAgainstBg,
    safeContainerColor: theme.primary.safeContainerColor
  }
});

export type ITheme = DefaultTheme;
