import { forwardRef, useState, useImperativeHandle, useRef } from 'react';

export interface TimerRef {
  startTimer(ms: number): void;
}

interface TimerChildrenProps {
  time: number;
  isFinished: boolean;
  prettyTime: string;
}

interface TimerProps {
  children: ({}: TimerChildrenProps) => React.ReactNode;
}

const UPDATE_INTERVAL = 50;

const Timer = forwardRef<TimerRef, TimerProps>(({ children }, ref) => {
  const [time, setTime] = useState(0);
  const isFinished = time === 0;
  const intervalTimer = useRef<NodeJS.Timer>(null);

  useImperativeHandle(
    ref,
    () => ({
      startTimer: (time) => {
        setTime(time);
        clearInterval(intervalTimer.current);
        intervalTimer.current = setInterval(() => {
          setTime((prevTime) => {
            const newResult = prevTime - UPDATE_INTERVAL;
            if (newResult <= 0) clearInterval(intervalTimer.current);
            return newResult;
          });
        }, UPDATE_INTERVAL);
      }
    }),
    [setTime]
  );

  return <>{children({ isFinished, time, prettyTime: (time / 1000).toFixed(2) })}</>;
});

export default Timer;
