import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem 0;
  letter-spacing: 0.08rem;
`;
