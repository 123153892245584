import styled, { css } from 'styled-components';
import { Form, ErrorMessage } from 'formik';
import useSellerTheme from '../../hooks/useSellerTheme';

export const FormTitle = styled.h1`
  margin: 1.5rem auto;
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Inter';
  color: ${(p) => p.theme.colors.mainContrast};
`;

export const Order = styled.p`
  margin: 1.5rem auto;
  width: 100%;
  padding: 0 4rem;
  max-width: 400px;
  font-size: 1.4rem;
  font-weight: initial;
  color: ${(p) => p.theme.colors.mainContrast};
`;

export const FormSubtitle = styled.p`
  margin: 1.5rem auto;
  width: 100%;
  padding: 0 4rem;
  max-width: 400px;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: 'Inter';
  color: ${(p) => p.theme.colors.mainContrast};
`;

export const FormLogo = styled.img.attrs((p) => {
  const {
    theme: { logo }
  } = useSellerTheme();
  return { ...p, src: logo };
})`
  margin: 0 auto;
  max-width: 300px;
  max-height: 200px;

  @media screen and (max-width: 425px) {
    max-width: 80%;
  }
`;

export const FormButton = styled.button.attrs((p) => ({
  ...p,
  onClick: p.disabled ? undefined : p.onClick
}))`
  background: #ffffff;
  font-family: 'Inter';
  // border: 1px solid #d0d5dd;
  // border: none;
  border: 1px solid ${(p) => p.theme.colors.safeContainerColor};
  border-radius: 5px;
  margin: 1rem;
  padding: 1.5rem 1.5rem;
  width: fit-content;
  color: #667085;
  font-weight: bold;
  letter-spacing: 0.12rem;
  font-size: 1.7rem;
  display: flex;
  flex-direction: row;
  padding-left: 0.8rem;
  align-items: center;
  cursor: pointer;

  outline: none;
  transition: box-shadow 0.1s ease-in-out;
  ${(p) =>
    !p.disabled &&
    css`
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
      &:hover {
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
      }
      &:active {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      background: #717171;
      color: #ffffff;
    `}
`;

export const FormButtonIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 2rem 4rem;
  max-width: 400px;
  border: 1px solid ${(p) => p.theme.colors.mainContrast};
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
`;

export const FormField = styled.input`
  border-radius: 0.5rem;
  // border: none;
  border: 1px solid ${(p) => p.theme.colors.safeContainerColor};
  font-size: 1.6rem;
  font-family: 'Inter';
  padding: 1rem 0.5rem;
  margin: 0.75rem 0;
  z-index: 10;
  outline: none;
  width: 100%;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }

  & {
    resize: none;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const FormFieldLabel = styled.label`
  font-size: 1.6rem;
  font-family: 'Inter';
  color: ${(p) => p.theme.colors.mainContrast};
  font-weight: 700;
`;

export const FormFieldErrorMessage = styled(ErrorMessage)`
  font-family: 'Inter';
  font-size: 1.4rem;
  color: white;
  background-color: #f04438;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  padding-top: 2rem;
  margin-top: -2rem;
`;

export const FormErrorMessage = styled.p`
  font-family: 'Inter';
  font-size: 1.4rem;
  color: white;
  background-color: #f04438;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
`;

export const Red = styled.span`
  color: #f04438;
`;
