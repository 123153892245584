import { getProjectThemeWithContrasts } from '@abbiamo/seller-theme-utils/dist';
import { getTheme } from 'src/theme';
import { GlobalStyles } from 'src/theme/global';
import { ThemeProvider } from 'styled-components';
import useSellerTheme from './hooks/useSellerTheme';

const ThemeDeps: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme } = useSellerTheme();
  return (
    <ThemeProvider theme={getTheme(getProjectThemeWithContrasts(theme))}>
      {children}
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default ThemeDeps;
