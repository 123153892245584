import * as NotFoundComponents from './not-found.styles';
import * as FormComponents from '../shared/form.styles';
import * as CommonComponents from '../shared/common.styles';

const NotFoundPage = () => {
  return (
    <NotFoundComponents.Container>
      <FormComponents.FormLogo />
      <FormComponents.FormTitle>Nenhuma página válida encontrada</FormComponents.FormTitle>
      <NotFoundComponents.SubTitle>
        A página que você esta tentando não é válida, ou não foi encontrada
      </NotFoundComponents.SubTitle>
      <CommonComponents.PoweredByImage />
    </NotFoundComponents.Container>
  );
};

export default NotFoundPage;
