import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.main};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem 0;
  letter-spacing: 0.08rem;
`;

export const SubTitle = styled.p`
  margin: 1.5rem auto;
  width: 100%;
  padding: 0 4rem;
  max-width: 400px;
  font-size: 1.8rem;
  font-weight: initial;
  color: ${(p) => p.theme.colors.mainContrast};
`;
