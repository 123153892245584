import { useQuery } from 'react-query';
import { RetiramoApi } from '../services/api';
import { useSearchParam } from 'react-use';

export interface RetiramoTheme {
  logo: string;
  logo_bg: string;
  primary: string;
  carrier_name: string;
  favicon: string;
  logo_link?: string;
}

export const defaultTheme: RetiramoTheme = {
  carrier_name: 'Abbiamo',
  logo_bg: '6ed796',
  logo: '/logos/abbiamo.svg',
  primary: '#6ed796',
  favicon: 'https://abbiamo-public.s3.amazonaws.com/seller-favicons/favicon.ico'
};

const useSellerTheme = () => {
  const sid = useSearchParam('sid');
  const { data, isLoading } = useQuery(['seller-theme', sid], () =>
    sid ? RetiramoApi.getSellerTheme(sid) : defaultTheme
  );

  const sellerTheme = data || defaultTheme;

  return { theme: sellerTheme, isLoading };
};

export default useSellerTheme;
