import { calculatePoweredByUrl } from '@abbiamo/seller-theme-utils/dist';
import styled from 'styled-components';

export const PoweredByImage = styled.img.attrs((props) => ({
  ...props,
  src: calculatePoweredByUrl(props.theme.colors.main)
}))`
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 200px;

  @media screen and (max-width: 425px) {
    max-width: 50%;
  }
`;
