import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnMount: false
    }
  }
});

const Deps: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || ' ');
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default Deps;
