import useSellerTheme, { defaultTheme } from 'src/hooks/useSellerTheme';
import { Helmet } from 'react-helmet';

const loadingFavicon = '/imgs/loading.ico';

const Headers = () => {
  const {
    theme: { carrier_name, favicon },
    isLoading
  } = useSellerTheme();
  return (
    <Helmet>
      <title>{isLoading ? 'Carregando' : `Retirada | ${carrier_name}`}</title>
      <link rel="icon" href={isLoading ? loadingFavicon : favicon || defaultTheme.favicon} />
    </Helmet>
  );
};

export default Headers;
