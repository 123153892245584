import * as yup from 'yup';
// import { onlyNumbers } from './form-page.utils';

export const FormValidationSchema = (order) =>
  yup.object().shape({
    code: yup.string().trim().length(6, 'Deve conter 6 caracteres').required('Obrigatório'),
    name: yup.string().trim().required('Obrigatório'),
    description: yup.string().trim().max(255, 'Máximo 255 de caracteres').optional(),
    document_number: yup.string().when([], {
      is: () => order.seller_group_id === 'cec021df-d4ac-42ef-a085-e90e895ba571',
      then: yup.string().length(11, 'Deve ser um CPF').required('Obrigatório'),
      otherwise: yup.string().required('Obrigatório')
    })
    // document_number: yup.lazy((value) => {
    //   const base = yup.string();
    //   const length = onlyNumbers(value).length;
    //   if (length < 11)
    //     return base.length(11, 'Deve conter 11 ou 14 caracteres').required('Obrigatório');

    //   if (length > 11 && length < 14)
    //     return base.length(14, 'Deve conter 11 ou 14 caracteres').required('Obrigatório');

    //   return base.required('Obrigatório');
    // }),
  });
